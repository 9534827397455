import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'

/**
 * 商户区域分布情况
 */
export const regionalRiskInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/bus/regionalRiskInfo',
        params
    })
}

/**
 * 风险等级情况统计
 */
export const riskStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/bus/riskStatistics',
        params
    })
}

/**
 * 风险商户新增趋势
 */
export const riskAdd = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/riskAdd',
        params
    })
}

/**
 * 风险商户累计趋势
 */
export const riskCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/riskCumulative',
        params
    })
}

/**
 * 风险商户按城市/地区统计
 */
export const riskCityStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/riskCityStatistics',
        params
    })
}

/**
 * 预付码按领域/行业统计
 */
export const riskIndustryStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/riskIndustryStatistics',
        params
    })
}

/**
 * 信用等级情况统计
 */
export const cerditStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/bus/cerditStatistics',
        params
    })
}
/**
 * 信用等级区域分布情况
 */
export const regionalCreditInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/bus/regionalCreditInfo',
        params
    })
}
/**
 * 信用等级商户数按城市/地区统计
 */
export const creditCityStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/creditCityStatistics',
        params
    })
}
/**
 * 信用等级按领域/行业统计
 */
export const creditIndustryStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/creditIndustryStatistics',
        params
    })
}
/**
 * 信用等级新增趋势
 */
export const cerditAdd = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/cerditAdd',
        params
    })
}
/**
 * 信用等级累计趋势
 */
export const creditCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/creditCumulative',
        params
    })
}

/**
 * 预警等级商户数按城市/地区统计
 */
export const warningCityStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/warningCityStatistics',
        params
    })
}
/**
 * 预警等级情况统计
 */
export const earlyWarningStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/bus/earlyWarningStatistics',
        params
    })
}

/**
 * 预警等级区域分布情况
 */
export const regionalEarlyWarningInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/bus/regionalEarlyWarningInfo',
        params
    })
}
/**
 * 预警等级按领域/行业统计
 */
export const warningIndustryStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/warningIndustryStatistics',
        params
    })
}
/**
 * 预警等级新增趋势
 */
export const warningAdd = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/warningAdd',
        params
    })
}
/**
 * 预警等级累计趋势
 */
export const warningCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/warningCumulative',
        params
    })
}
